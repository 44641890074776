import types from '../types'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import {getLogs} from './getLogs'
import {getTimeStats} from './getTimeStats'
import {getOrgDetails} from './getOrgDetails'
import {getMetrics} from './getMetrics'
import {getAuthKey} from './getAuthKey'
import {getBilling} from './getBilling'
import {getUserData} from './getUserData'
import {getVersionDetails} from './getVersionDetails'
import {getUsers} from './getUsers'
import {getVKGDomain} from './getVKGDomain'
import {getModelInformation} from './getModelInformation'
import {getModelAccess} from './getModelAccess'
import {getOrgModels} from './getOrgModels'
import {getLicenseKey} from './getLicenseKey'
import {validateLicenseKey} from './validateLicenseKey'
import {getVKGs} from './getVKGs'
import {getOrgModelMetrics} from './getOrgModelMetrics'

export const onSignInSuccess = (user, database) => (dispatch) => {
  if (user && user.uid) {
    let orgId
    const orgIdRef = database.ref(`users/${user.uid}/organization/orgId`)
    orgIdRef.get()
        .then(async (snapshot) => {
          const val = snapshot.val()
          orgId = val
          dispatch(getTimeStats(orgId, user.uid))
          await dispatch(getUserData(database, user.uid))
          await dispatch(getOrgDetails(database, orgId))
          await dispatch(getOrgModels(database, orgId))
          dispatch(getMetrics(database, orgId))
          await dispatch(getBilling(database, orgId))
          await dispatch(getAuthKey(database, orgId))
          dispatch(getVersionDetails(database))
          await dispatch(getUsers(database, orgId))
          await dispatch({ // dispatch user and org payload before getVKGDomain
            type: types.STORE_PAYLOAD,
            payload: {
              user,
              orgId: orgId,
              hasAttemptedSignIn: true,
              isVerified: user.emailVerified,
            },
          })
          const models = await dispatch(getModelInformation(database, orgId))
          const modelAccess = await dispatch(getModelAccess(database, orgId))
          await dispatch(getLogs(orgId))
          await dispatch(getOrgModelMetrics(orgId))
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              modelInformation: models,
              modelAccess: modelAccess,
            },
          })
          await dispatch(getVKGDomain(database, orgId))
          // validate org license key
          const licenseKey = await getLicenseKey(database, orgId)
          if (licenseKey) {
            const isValidLicenseKey = await validateLicenseKey(licenseKey)
            if (!isValidLicenseKey) {
              dispatch({ // org does not have valid license
                type: types.STORE_PAYLOAD,
                payload: {
                  isLoading: false,
                  userMessage: 'Your organization does not have a valid license.',
                  notificationType: 3,
                  notificationIcon: 'warning',
                  isNotificationVisible: true,
                },
              })
            } else {
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  licenseKey: licenseKey,
                },
              },
              )
            }
          }
          dispatch(getVKGs())
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
            },
          })
        })
        .catch((err) => console.log('error', err))
  }
}
