import types from '../types'
import Helpers from '../Helpers'

export const getTotalLogs =
  (orgId) =>
    async (dispatch, getState) => {
      const userReducer = getState().userReducer
      const authKey = userReducer.authKey
      const path = process.env.REACT_APP_METRICS_URL + `api/query/requests/total`
      const headers = {
        'orgId': orgId,
        'authKey': authKey,
      }
      Helpers.fetchGet(path, headers)
          .then((res) => {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                totalLogs: res.requests,
              },
            })
          })
          .catch((err) => {
            console.log('Error: ', err)
          })
    }
